export default {
    coins: [
        {price: 0.05, name: "kvartz"},
        {price: 0.1, name: "agat"},
        {price: 0.2, name: "rubin"},
        {price: 0.3, name: "izumrud"},
        {price: 0.5, name: "korall"},
        {price: 0.7, name: "biruza"},
        {price: 1, name: "kremen"},
        {price: 1.5, name: "obsidian", hidden: true},
        {price: 2, name: "zoloto", hidden: true}
    ],
    network: '1',
    contract: "0xe0566208e3a66078e109bae35ce6e82417d89833"
}
